<app-breadcrumb [active_item]="'Employees'" [icon]="'fa-user-shield fas'" [title]="'Id Cards'"
                [url]="'/employee'"></app-breadcrumb>
<div class="container-fluid">

    <ng-template #idcardModal>
        <div class="card">
            <form [formGroup]="updateIdcardForm">
                <div class="card-header">
                    <div class="row">
                        <div class="col-9 text-start">
                            <h4 class="modal-title ">Update Id Card</h4>
                        </div>
                        <div class="col-3 text-end">
                            <i class="fa fa-window-close text-end text-danger font-size-20" (click)="closeModal()"></i>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Designation</span>
                            <input type="text" class="form-control input-group" formControlName="designation"
                                   placeholder="designation"/>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Date Of Joining</span>
                            <input type="date" class="form-control w-100" formControlName="date_of_joining"
                                   placeholder="Joining Date"/>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Father Name</span>
                            <input type="text" class="form-control input-group" formControlName="father_name"
                                   placeholder="Father Name"/>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Date Of Birth</span>
                            <input type="date" class="form-control" formControlName="dob"
                                   placeholder="Date of Birth"/>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Gender</span>
                            <select type="select" class="form-control dropdown-basic" formControlName="gender">
                                <option value="">Select Gender</option>
                                <option value="MALE">MALE</option>
                                <option value="FEMALE">FEMALE</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Highest Qualification</span>
                            <input type="text" class="form-control input-group" formControlName="highest_qualification"
                                   placeholder="Qualification"/>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <span>Marital Status</span>
                            <input type="text" class="form-control" formControlName="marital_status"
                                   placeholder="Marital Status"/>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button (click)="updateIdCard()"
                            aria-label="Close"
                            class="btn btn-primary color-white pull-right"
                            data-dismiss="modal">
                        <i class="fas fa-edit "></i> Update
                    </button>
                </div>
            </form>
        </div>
    </ng-template>

    <div class="row">
        <div class="col-sm-12">
            <div class="card card-modified">
                <div class="card-header p-25px">
                    <h5 class="text-start">Id Card List</h5>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive" style="overflow-x: auto;">
                            <table class="table text-center">
                                <thead class="thead-light .bg-*">
                                <tr>
                                    <th class="text-center" scope="col" width="5%">Sno.</th>
                                    <th class="text-center" scope="col" width="10%">Emp No</th>
                                    <th class="text-center" scope="col" width="10%">Name</th>
                                    <th class="text-center" scope="col" width="10%">Email</th>
                                    <th class="text-center" scope="col" width="10%">Contact</th>
                                    <th class="text-center" scope="col" width="10%">Address</th>
                                    <th class="text-center" scope="col" width="10%">Designation</th>
                                    <th class="text-center" scope="col" width="10%">Date Of Join</th>
                                    <th class="text-center" scope="col" width="10%">Profile Image</th>
                                    <th class="text-center" scope="col" width="10%">QR Code</th>
                                    <th class="text-center" scope="col" width="5%">Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="employees && employees.length > 0; else noDataToShow">
                                <tr *ngFor="let employee of employees;  let i = index">
                                    <td class="text-center">
                                        {{ (pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1 }}
                                        .
                                    </td>
                                    <td class="text-center">{{ employee?.emp_no }}</td>
                                    <td class="text-center">{{ employee?.user?.name }}</td>
                                    <td class="text-center">{{ employee?.user?.email }}</td>
                                    <td class="text-center">{{ employee?.user?.contact }}</td>
                                    <td class="text-center">{{ employee?.user?.address }}</td>
                                    <td class="text-center">{{ employee?.designation }}</td>
                                    <td class="text-center">{{ employee?.date_of_joining }}</td>
                                    <td class="text-center">
                                        <div *ngIf="employee && employee?.profile_image" class="btn-group"
                                             role="group">
                                            <button (click)="bannerUpload.click()" class="btn btn-primary"
                                                    title="Upload Banner" type="button">
                                                <i class="fas fa-cloud-upload-alt"></i>
                                            </button>
                                            <button (click)="openPrifileImage(employee)" class="btn btn-primary"
                                                    title="View Banner" type="button">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </div>
                                        <button (click)="bannerUpload.click()"
                                                *ngIf="employee && !employee?.profile_image"
                                                class="btn btn-primary color-white"
                                                title="Upload">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                        </button>
                                        <input #bannerUpload (change)="onFileChange($event, 'PROFILE_IMAGE', employee)"
                                               class="form-control-file display-hidden"
                                               type="file">
                                    </td>
                                    <td class="text-center">
                                        <div *ngIf="employee && employee?.qr_code" class="btn-group"
                                             role="group">
                                            <button (click)="qrUpload.click()" class="btn btn-primary"
                                                    title="Upload Banner" type="button">
                                                <i class="fas fa-cloud-upload-alt"></i>
                                            </button>
                                            <button (click)="openQrCode(employee)" class="btn btn-primary"
                                                    title="View Banner" type="button">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </div>
                                        <button (click)="qrUpload.click()" *ngIf="employee && !employee?.qr_code"
                                                class="btn btn-primary color-white"
                                                title="Upload">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                        </button>
                                        <input #qrUpload (change)="onFileChange($event, 'QR_CODE', employee)"
                                               class="form-control-file display-hidden"
                                               type="file">
                                    </td>

                                    <td class="text-center" style="width: 10rem;">
                                        <i (click)="openUpdateIdcardModal(idcardModal,employee)"
                                           aria-hidden="true"
                                           class="fa-solid fa-edit f_20 text-info cursor-pointer font-weight-light mt-1  ml-5 "
                                           title="Edit" style="margin-right: 5px;"></i>
                                    </td>
                                </tr>
                                </tbody>
                                <ng-template #noDataToShow>
                                    <tbody>
                                    <tr>
                                        <td class="text-center" colspan="9">
                                            <span>No data to show</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="pagination-top">
                        <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                        [boundaryLinks]="true"
                                        [collectionSize]="pagination.total"
                                        [maxSize]="5"
                                        [pageSize]="pagination.perpage"
                                        class="d-flex justify-content-center">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
