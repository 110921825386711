import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserService} from "../../../../services/user.service";
import {StorageService} from "../../../../services/storage.service";
import {AuthService} from "../../../../services/auth.service";
import {DataService} from "../../../../services/data.service";

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
    profileImg = null;
    user: any;
    userData: any;

    constructor(public router: Router,
                private userService: UserService,
                private authService: AuthService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit() {
        this.getUserDetail();
    }

    getUserDetail() {
        this.userService.detail(this.user.user_id).subscribe(data => {
            if (data) {
                this.userData = data;
                this.profileImg = data['files']?.url;
            }
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    logout() {
        this.authService.logout({'login':'WEB'}).subscribe(data => {
            StorageService.clearAll();
            this.router.navigate(['/auth/login']).then(() => {
                window.location.reload();
            });
        }, error => {
            this.router.navigate(['/auth/login']).then(() => {
                window.location.reload();
            });
        });
    }
}
