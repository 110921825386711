import {Injectable} from '@angular/core';

@Injectable()
export class PermissionConstant {

    static ALL = 'ALL';


    static ADD_EMPLOYEE = 'EMPLOYEE_ADD_EMPLOYEE';
    static LIST_EMPLOYEE = 'EMPLOYEE_LIST_EMPLOYEE';


    static ADD_CAREER = 'CAREER_ADD_CAREER';
    static LIST_CAREER = 'CAREER_LIST_CAREER';


    static ADD_BLOG = 'BLOG_ADD_BLOG';
    static LIST_BLOG = 'BLOG_LIST_BLOG';


    static ADD_META_TAG = 'META_TAGS_ADD_META_TAG';
    static LIST_META_TAG = 'META_TAGS_LIST_META_TAG';


    static ADD_CAREER_DATA = 'CAREER_DATA_ADD_CAREER_DATA';
    static LIST_CAREER_DATA = 'CAREER_DATA_LIST_CAREER_DATA';


    static ADD_DEPARTMENT = 'DEPARTMENT_ADD_DEPARTMENT';
    static LIST_DEPARTMENT = 'DEPARTMENT_LIST_DEPARTMENT';


    static ADD_USER_DEPARTMENT = 'DEPARTMENT_ADD_USER_DEPARTMENT';
    static LIST_USER_DEPARTMENT = 'DEPARTMENT_LIST_USER_DEPARTMENT';

    static LIST_CONTACT = 'CONTACT_LIST_CONTACT';
    static ADD_CONTACT = 'CONTACT_ADD_CONTACT';

    static LIST_ATTENDANCE = 'ATTENDANCE_LIST_ATTENDANCE';
    static ADD_ATTENDANCE = 'ATTENDANCE_ADD_ATTENDANCE';

    static ADD_ACCESS = 'ACCESS_ADD_ACCESS';
    static LIST_ACCESS = 'ACCESS_LIST_ACCESS';

    static ADD_LEAVE = 'LEAVE_ADD_LEAVE';
    static LIST_LEAVE = 'LEAVE_LIST_LEAVE';

    static ADD_HOLIDAY = 'HOLIDAY_ADD_HOLIDAY';
    static LIST_HOLIDAY = 'HOLIDAY_LIST_HOLIDAY';

    static ADD_PROFILE = 'PROFILE_ADD_PROFILE';
    static LIST_PROFILE = 'PROFILE_LIST_PROFILE';

    static ADD_DEVICE = 'DEVICE_ADD_DEVICE';
    static LIST_DEVICE = 'DEVICE_LIST_DEVICE';

    static LIST_TEMPLATE = 'TEMPLATE_LIST_TEMPLATE';
    static ADD_INVOICE = 'INVOICE_ADD_INVOICE';
    static EDIT_INVOICE = 'INVOICE_EDIT_INVOICE';
    static LIST_INVOICE = 'INVOICE_LIST_INVOICE';

    static ADD_CHAT = 'CHAT_ADD_CHAT';
    static LIST_CHAT = 'CHAT_LIST_CHAT';

}
