import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Subject} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';

@Injectable({
    providedIn: 'root'
})
export class DataService extends BaseService {
    _messageSource = new Subject<any>();
    message$ = this._messageSource.asObservable();

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    sendMessage(message: any) {
        this._messageSource.next(message);
    }
}
