import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BaseService} from "./base.service";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.ROLES(), data);
  }

  update(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ROLES(unique_id), data);
  }

  detail(unique_id): Observable<any> {
    return this.getRequest(AppUrl.ROLES(unique_id));
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.ROLES(), data);
  }

  updateStatus(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ROLES_STATUS(unique_id), data);
  }

}
