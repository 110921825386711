import {Component, HostListener, OnInit} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";
import {GlobalService} from "../../shared/services/global.service";
import {AuthService} from "../../shared/services/auth.service";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {DataService} from "../../shared/services/data.service";
import {UserService} from "../../shared/services/user.service";
import {AppComponent} from "../../app.component";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public show: boolean = false
    submittedLogin: any = false;
    isBusy: any = false;
    private echo: Echo;
    window = {Pusher: null};
    user: any;
    userData: any;

    constructor(private fb: FormBuilder,
                public router: Router,
                private globalService: GlobalService,
                private authService: AuthService,
                private dataService: DataService,
                private userService: UserService,
                private appComponent: AppComponent) {
        this.checkLogin();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.loginForm = this.fb.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
            login:['WEB']
        });
    }

    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.user = StorageService.getItem('self');
            this.reloadSelf();
        } else {
            this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type,
                'unique_id': data.unique_id
            });
            this.router.navigateByUrl('/dashboard');
        }, e => {
            StorageService.clearAll();
            this.router.navigateByUrl('auth/login');
        });
    }

    login() {
        this.submittedLogin = true;
        if (!this.loginForm.valid) {
            return;
        }
        if (this.submittedLogin) {
            if (this.checkLoggedInStatus()) {
                this.setSelfData();
                this.connectToSocket();
                this.router.navigateByUrl('/dashboard');
            } else {
                this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                    if (data.id) {
                        this.globalService.setAccessToken(data.token);
                        this.globalService.setSelf({
                            'name': data.name,
                            'email': data.email,
                            'contact': data.contact,
                            'user_id': data.id,
                            'username': data.username,
                            'user_type': data.user_type,
                            'unique_id': data.unique_id
                        });
                        this.user = StorageService.getItem('self');
                        this.connectToSocket();
                        this.router.navigateByUrl('/dashboard');
                        this.loginForm.reset();
                    }
                }, error => {
                    this.isBusy = false;
                    this.submittedLogin = false;
                });
            }
        }
    }

    showPassword() {
        this.show = !this.show
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }

    connectToSocket() {
        console.log('connect to socket at login');

        this.window.Pusher = Pusher;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: '29f3edbd069470d91803',
            cluster: 'ap2',
            // wsHost: window.location.hostname,
            // wsPort: 6001,
            forceTLS: true,
            // disableStats: true,
        });
        this.listenToChat();
    }

    listenToChat() {
        this.echo.channel(`chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('at login component ', data);
                this.dataService.sendMessage(data);
                this.playNotificationSound();
            });
    }

    playNotificationSound() {
        const audio = new Audio();
        audio.src = 'assets/sounds/notification.mp3'; // Path to your sound file
        audio.load();
        audio.play();
    }
}
